import React from 'react';
import cx from 'classnames';

import { Icon } from '../Icons';

import styles from './loader.module.scss';

interface LoaderProps {
  className?: string;
}

interface LoaderBouncingProps extends LoaderProps {
  size?: number;
}

export const LoaderBouncing = React.memo<LoaderBouncingProps>(
  ({ size = 96, className }) => {
    return (
      <div className={cx(styles.wrapper, className)} style={{ width: size }}>
        <Icon.Loader className={styles.svg} />
      </div>
    );
  },
);

export const LoaderFixed = React.memo<LoaderProps>(({ className }) => {
  return (
    <div className={cx(styles.fixed, className)}>
      <LoaderBouncing />
    </div>
  );
});

export const LoaderCentred = React.memo<LoaderProps>(({ className }) => {
  return (
    <div className={cx(styles.centered, className)}>
      <LoaderBouncing size={56} />
    </div>
  );
});

export const LoaderContent = React.memo<LoaderProps>(({ className }) => {
  return (
    <div className={cx(styles.content, className)}>
      <LoaderBouncing size={56} />
    </div>
  );
});
