import React, { lazy } from 'react';
import { LoaderFixed } from './components/Loaders';
import { isStaging, useSavePublicIdInLocalStorage } from 'helpers';

const MarketApp = lazy(() => import('containers/Apps/MarketApp'));
const PlatformApp = lazy(() => import('containers/Apps/PlatformApp'));
const QuizApp = lazy(() => import('containers/Apps/QuizApp'));
const TactistatsApp = lazy(() => import('containers/Apps/TactistatsApp'));

const GlobalApp = () => {
  useSavePublicIdInLocalStorage();

  const subdomain = window.location.host?.split('.')[0];
  const pathname = window.location.pathname;

  const renderContent = () => {
    if (subdomain === 'market') {
      return <MarketApp />;
    }

    if (subdomain === 'quiz') {
      return <QuizApp />;
    }

    if (pathname.includes('/tactistats/') && isStaging()) {
      return <TactistatsApp />;
    }

    return <PlatformApp />;
  };

  return (
    <React.Suspense fallback={<LoaderFixed />}>
      {/* <HelmetProvider>{renderContent()}</HelmetProvider> */}
      {renderContent()}
    </React.Suspense>
  );
};

export default GlobalApp;
