import React, { memo } from 'react';

export type IconProps = React.SVGProps<SVGSVGElement>;
export type IconType = React.NamedExoticComponent<IconProps>;

interface RoundedIconProps {
  Icon: IconType;
  iconStyle?: React.CSSProperties;
  className?: string;
  style?: React.CSSProperties;
  onClick?: () => void;
}

export const Rounded = memo<RoundedIconProps>(
  ({
    Icon,
    className = undefined,
    iconStyle = {},
    style = {},
    onClick = () => undefined,
  }) => (
    <div className={className} style={style} onClick={onClick}>
      <Icon style={iconStyle} />
    </div>
  ),
);

export const Close = memo<IconProps>(props => (
  <svg width={32} height={32} viewBox="0 0 33 32" fill="#828282" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M25.9011 6.52773C26.4218 7.04843 26.4218 7.89265 25.9011 8.41335L9.90114 24.4133C9.38044 24.934 8.53622 24.934 8.01552 24.4133C7.49483 23.8927 7.49483 23.0484 8.01552 22.5277L24.0155 6.52773C24.5362 6.00703 25.3804 6.00703 25.9011 6.52773Z"
      stroke="none"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8.01552 6.52773C8.53622 6.00703 9.38044 6.00703 9.90114 6.52773L25.9011 22.5277C26.4218 23.0484 26.4218 23.8927 25.9011 24.4133C25.3804 24.934 24.5362 24.934 24.0155 24.4133L8.01552 8.41335C7.49483 7.89265 7.49483 7.04843 8.01552 6.52773Z"
      stroke="none"
    />
  </svg>
));

export const TargetIcon = memo<IconProps>(props => (
  <svg width={24} height={24} viewBox="0 0 24 24" fill="#141106" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12 3C7.02944 3 3 7.02944 3 12C3 16.9706 7.02944 21 12 21C16.9706 21 21 16.9706 21 12C21 7.02944 16.9706 3 12 3ZM1 12C1 5.92487 5.92487 1 12 1C18.0751 1 23 5.92487 23 12C23 18.0751 18.0751 23 12 23C5.92487 23 1 18.0751 1 12Z"
      stroke="none"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M17 12C17 11.4477 17.4477 11 18 11H22C22.5523 11 23 11.4477 23 12C23 12.5523 22.5523 13 22 13H18C17.4477 13 17 12.5523 17 12Z"
      stroke="none"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M1 12C1 11.4477 1.44772 11 2 11H6C6.55228 11 7 11.4477 7 12C7 12.5523 6.55228 13 6 13H2C1.44772 13 1 12.5523 1 12Z"
      stroke="none"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12 1C12.5523 1 13 1.44772 13 2V6C13 6.55228 12.5523 7 12 7C11.4477 7 11 6.55228 11 6V2C11 1.44772 11.4477 1 12 1Z"
      stroke="none"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12 17C12.5523 17 13 17.4477 13 18V22C13 22.5523 12.5523 23 12 23C11.4477 23 11 22.5523 11 22V18C11 17.4477 11.4477 17 12 17Z"
      stroke="none"
    />
  </svg>
));

export const MarketNotification = memo<IconProps>(props => (
  <svg width={16} height={16} viewBox="0 0 16 16" {...props}>
    <path
      d="M6.41097 1.03306C7.02258 -0.344352 8.97742 -0.344352 9.58903 1.03306C9.96491 1.87956 10.9384 2.2828 11.8028 1.95001C13.2092 1.40851 14.5915 2.79079 14.05 4.19724C13.7172 5.0616 14.1204 6.03509 14.9669 6.41097C16.3444 7.02258 16.3444 8.97742 14.9669 9.58903C14.1204 9.96491 13.7172 10.9384 14.05 11.8028C14.5915 13.2092 13.2092 14.5915 11.8028 14.05C10.9384 13.7172 9.96491 14.1204 9.58903 14.9669C8.97742 16.3444 7.02258 16.3444 6.41097 14.9669C6.03509 14.1204 5.0616 13.7172 4.19724 14.05C2.79079 14.5915 1.40851 13.2092 1.95001 11.8028C2.2828 10.9384 1.87956 9.96491 1.03306 9.58903C-0.344352 8.97742 -0.344352 7.02258 1.03306 6.41097C1.87956 6.03509 2.2828 5.0616 1.95001 4.19724C1.40851 2.79079 2.79079 1.40851 4.19724 1.95001C5.0616 2.2828 6.03509 1.87956 6.41097 1.03306Z"
      fill="var(--error-red)"
      stroke="none"
    />
    <circle cx="8" cy="10.5" r="1" fill="var(--beige)" stroke="none" />
    <path d="M7 4H9V9H7V4Z" fill="var(--beige)" stroke="none" />
  </svg>
));

export const Clock = memo<IconProps>(props => (
  <svg width="16" height="16" viewBox="0 0 16 16" fill="#06A701" {...props}>
    <g clipPath="url(#clip0_1010_1860)">
      <path
        stroke="none"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.86702 2.28133C4.55332 2.28133 1.86702 4.96762 1.86702 8.28133C1.86702 11.595 4.55332 14.2813 7.86702 14.2813C11.1807 14.2813 13.867 11.595 13.867 8.28133C13.867 4.96762 11.1807 2.28133 7.86702 2.28133ZM0.533691 8.28133C0.533691 4.23124 3.81694 0.947998 7.86702 0.947998C11.9171 0.947998 15.2004 4.23124 15.2004 8.28133C15.2004 12.3314 11.9171 15.6147 7.86702 15.6147C3.81694 15.6147 0.533691 12.3314 0.533691 8.28133Z"
      />
      <path
        stroke="none"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.86686 3.6145C8.23505 3.6145 8.53353 3.91298 8.53353 4.28117V7.86915L10.8317 9.01822C11.161 9.18288 11.2945 9.58333 11.1298 9.91264C10.9652 10.242 10.5647 10.3754 10.2354 10.2108L7.56872 8.87745C7.34286 8.76453 7.2002 8.53368 7.2002 8.28117V4.28117C7.2002 3.91298 7.49867 3.6145 7.86686 3.6145Z"
      />
      <path
        stroke="none"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.86702 2.28133C4.55332 2.28133 1.86702 4.96762 1.86702 8.28133C1.86702 11.595 4.55332 14.2813 7.86702 14.2813C11.1807 14.2813 13.867 11.595 13.867 8.28133C13.867 4.96762 11.1807 2.28133 7.86702 2.28133ZM0.533691 8.28133C0.533691 4.23124 3.81694 0.947998 7.86702 0.947998C11.9171 0.947998 15.2004 4.23124 15.2004 8.28133C15.2004 12.3314 11.9171 15.6147 7.86702 15.6147C3.81694 15.6147 0.533691 12.3314 0.533691 8.28133Z"
      />
      <path
        stroke="none"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.86735 3.6145C8.23554 3.6145 8.53402 3.91298 8.53402 4.28117V7.86915L10.8322 9.01822C11.1615 9.18288 11.295 9.58333 11.1303 9.91264C10.9656 10.242 10.5652 10.3754 10.2359 10.2108L7.56921 8.87745C7.34335 8.76453 7.20068 8.53368 7.20068 8.28117V4.28117C7.20068 3.91298 7.49916 3.6145 7.86735 3.6145Z"
      />
    </g>
    <defs>
      <clipPath id="clip0_1010_1860">
        <rect width="16" height="16" fill="white" />
      </clipPath>
    </defs>
  </svg>
));

export const MarketCart = memo<IconProps>(props => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="#F2C94C" {...props}>
    <g clipPath="url(#clip0_1010_2937)">
      <path
        stroke="none"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.1084 21.3042C7.1084 20.1996 8.00383 19.3042 9.1084 19.3042C10.213 19.3042 11.1084 20.1996 11.1084 21.3042C11.1084 22.4088 10.213 23.3042 9.1084 23.3042C8.00383 23.3042 7.1084 22.4088 7.1084 21.3042Z"
      />
      <path
        stroke="none"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M18.1084 21.3042C18.1084 20.1996 19.0038 19.3042 20.1084 19.3042C21.213 19.3042 22.1084 20.1996 22.1084 21.3042C22.1084 22.4088 21.213 23.3042 20.1084 23.3042C19.0038 23.3042 18.1084 22.4088 18.1084 21.3042Z"
      />
      <path
        stroke="none"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0.108398 1.3042C0.108398 0.751914 0.556114 0.304199 1.1084 0.304199H5.1084C5.58503 0.304199 5.99541 0.640584 6.08895 1.10794L6.92883 5.3042H23.1084C23.4064 5.3042 23.6889 5.43713 23.8789 5.66675C24.0689 5.89638 24.1465 6.19878 24.0907 6.49153L22.4893 14.889C22.3521 15.5796 21.9764 16.2 21.4279 16.6415C20.8822 17.0808 20.2 17.3152 19.4998 17.3042H9.79704C9.09677 17.3152 8.4146 17.0808 7.86888 16.6415C7.32065 16.2001 6.94503 15.5801 6.80773 14.8899C6.80767 14.8896 6.80778 14.8901 6.80773 14.8899L5.13719 6.5434C5.13041 6.51579 5.12478 6.48773 5.12035 6.45928L4.28871 2.3042H1.1084C0.556114 2.3042 0.108398 1.85648 0.108398 1.3042ZM7.32913 7.3042L8.76924 14.4994C8.81496 14.7296 8.94019 14.9364 9.12301 15.0835C9.30583 15.2307 9.53459 15.3089 9.76924 15.3044L9.7884 15.3042H19.5084L19.5276 15.3044C19.7622 15.3089 19.991 15.2307 20.1738 15.0835C20.3558 14.937 20.4807 14.7315 20.5269 14.5026L21.8997 7.3042H7.32913Z"
      />
    </g>
  </svg>
));

export const Copy = memo<IconProps>(props => (
  <svg width={16} height={16} viewBox="0 0 16 17" fill="#141106" {...props}>
    <g clipPath="url(#clip0_1859_74943)">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.34766 7.41958C6.97947 7.41958 6.68099 7.71805 6.68099 8.08624V14.0862C6.68099 14.4544 6.97947 14.7529 7.34766 14.7529H13.3477C13.7158 14.7529 14.0143 14.4544 14.0143 14.0862V8.08624C14.0143 7.71805 13.7158 7.41958 13.3477 7.41958H7.34766ZM5.34766 8.08624C5.34766 6.98167 6.24309 6.08624 7.34766 6.08624H13.3477C14.4522 6.08624 15.3477 6.98167 15.3477 8.08624V14.0862C15.3477 15.1908 14.4522 16.0862 13.3477 16.0862H7.34766C6.24309 16.0862 5.34766 15.1908 5.34766 14.0862V8.08624Z"
        stroke="none"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2.67969 2.75295C2.50288 2.75295 2.33331 2.82319 2.20828 2.94821C2.08326 3.07324 2.01302 3.24281 2.01302 3.41962V9.41962C2.01302 9.59643 2.08326 9.766 2.20828 9.89102C2.33331 10.016 2.50288 10.0863 2.67969 10.0863H3.34635C3.71454 10.0863 4.01302 10.3848 4.01302 10.753C4.01302 11.1211 3.71454 11.4196 3.34635 11.4196H2.67969C2.14925 11.4196 1.64055 11.2089 1.26547 10.8338C0.890401 10.4588 0.679688 9.95005 0.679688 9.41962V3.41962C0.679688 2.88918 0.890401 2.38048 1.26547 2.0054C1.64055 1.63033 2.14925 1.41962 2.67969 1.41962H8.67969C9.21012 1.41962 9.71883 1.63033 10.0939 2.0054C10.469 2.38048 10.6797 2.88918 10.6797 3.41962V4.08628C10.6797 4.45447 10.3812 4.75295 10.013 4.75295C9.64483 4.75295 9.34635 4.45447 9.34635 4.08628V3.41962C9.34635 3.24281 9.27612 3.07324 9.15109 2.94821C9.02607 2.82319 8.8565 2.75295 8.67969 2.75295H2.67969Z"
        stroke="none"
      />
    </g>
  </svg>
));

export const Lock = memo<IconProps>(props => (
  <svg width={32} height={32} viewBox="0 0 32 32" fill="#828282" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M6.66675 16C5.93037 16 5.33341 16.597 5.33341 17.3333V26.6667C5.33341 27.4031 5.93037 28 6.66675 28H25.3334C26.0698 28 26.6667 27.4031 26.6667 26.6667V17.3333C26.6667 16.597 26.0698 16 25.3334 16H6.66675ZM2.66675 17.3333C2.66675 15.1242 4.45761 13.3333 6.66675 13.3333H25.3334C27.5426 13.3333 29.3334 15.1242 29.3334 17.3333V26.6667C29.3334 28.8758 27.5426 30.6667 25.3334 30.6667H6.66675C4.45761 30.6667 2.66675 28.8758 2.66675 26.6667V17.3333Z"
      stroke="none"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M16 4.00001C14.5855 4.00001 13.229 4.56191 12.2288 5.56211C11.2286 6.5623 10.6667 7.91886 10.6667 9.33334V14.6667C10.6667 15.4031 10.0697 16 9.33333 16C8.59695 16 8 15.4031 8 14.6667V9.33334C8 7.21161 8.84285 5.17678 10.3431 3.67649C11.8434 2.1762 13.8783 1.33334 16 1.33334C18.1217 1.33334 20.1566 2.1762 21.6569 3.67649C23.1571 5.17678 24 7.21161 24 9.33334V14.6667C24 15.4031 23.403 16 22.6667 16C21.9303 16 21.3333 15.4031 21.3333 14.6667V9.33334C21.3333 7.91886 20.7714 6.5623 19.7712 5.56211C18.771 4.56191 17.4145 4.00001 16 4.00001Z"
      stroke="none"
    />
  </svg>
));

export const Loader = memo<IconProps>(props => (
  <svg width={98} height={98} viewBox="0 0 98 98" fill="#F2C94C" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M52.0135 9.10957C32.1875 7.58682 13.8698 21.0368 9.80307 41.0254C5.39876 62.6733 19.3774 83.7928 41.0253 88.1971C62.6733 92.6015 83.7927 78.6228 88.1971 56.9749C92.2638 36.9863 80.6576 17.4482 61.8127 11.1032L63.4181 3.21271C86.5945 10.4798 100.985 34.2469 96.0365 58.5698C90.7513 84.5473 65.4079 101.322 39.4304 96.0365C13.4529 90.7514 -3.32151 65.408 1.96367 39.4305C6.91223 15.1076 29.4453 -1.14731 53.6188 1.21903L52.0135 9.10957Z"
      stroke="none"
    />
    <path
      opacity="0.5"
      fillRule="evenodd"
      clipRule="evenodd"
      d="M71.1251 74.8284C74.2641 72.1408 76.9626 68.8317 79.0199 64.9624C87.8355 48.3826 81.5414 27.7957 64.9617 18.9801C49.8818 10.962 31.4869 15.4435 21.6491 28.7949L27.0175 31.6494C35.239 21.2087 49.9979 17.8191 62.1449 24.2778C75.7988 31.5377 80.9821 48.4917 73.7222 62.1455C72.1784 65.0489 70.1963 67.5693 67.9008 69.6685L71.1251 74.8284ZM59.4313 74.9852C52.0384 77.9488 43.4375 77.7548 35.8545 73.7229C23.7075 67.2642 18.2645 53.1331 22.3228 40.4788L16.9544 37.6244C11.3868 53.2463 17.9577 71.0024 33.0376 79.0205C42.5848 84.0968 53.4606 84.163 62.6525 80.1402L59.4313 74.9852Z"
      stroke="none"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M45.6634 57.3599C48.3602 58.4331 51.5256 58.1845 54.0972 56.4171C58.1936 53.6018 59.232 47.9987 56.4167 43.9023C53.6013 39.806 47.9982 38.7675 43.9019 41.5828C41.3303 43.3503 39.9638 46.2163 39.9994 49.1186L33.6381 53.4906C31.7503 47.0369 34.0936 39.83 39.937 35.814C47.2195 30.8089 57.1805 32.655 62.1855 39.9375C67.1906 47.2199 65.3445 57.1809 58.062 62.186C52.2186 66.2021 44.6506 65.807 39.3022 61.7318L45.6634 57.3599Z"
      stroke="none"
    />
  </svg>
));

export const RightArrow = memo<IconProps>(props => (
  <svg width="32" height="33" viewBox="0 0 32 33" fill="#828282" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M11.0585 8.05068C11.5792 7.52998 12.4234 7.52998 12.9441 8.05068L20.9441 16.0507C21.4648 16.5714 21.4648 17.4156 20.9441 17.9363L12.9441 25.9363C12.4234 26.457 11.5792 26.457 11.0585 25.9363C10.5378 25.4156 10.5378 24.5714 11.0585 24.0507L18.1157 16.9935L11.0585 9.9363C10.5378 9.4156 10.5378 8.57138 11.0585 8.05068Z"
      stroke="none"
    />
  </svg>
));
export const LeftArrow = memo<IconProps>(props => (
  <svg
    width="32"
    height="33"
    viewBox="0 0 32 33"
    fill="#828282"
    {...props}
    style={{ transform: 'rotate(180deg)' }}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M11.0585 8.05068C11.5792 7.52998 12.4234 7.52998 12.9441 8.05068L20.9441 16.0507C21.4648 16.5714 21.4648 17.4156 20.9441 17.9363L12.9441 25.9363C12.4234 26.457 11.5792 26.457 11.0585 25.9363C10.5378 25.4156 10.5378 24.5714 11.0585 24.0507L18.1157 16.9935L11.0585 9.9363C10.5378 9.4156 10.5378 8.57138 11.0585 8.05068Z"
      stroke="none"
    />
  </svg>
));

export const Search = memo<IconProps>(props => (
  <svg width={24} height={24} viewBox="0 0 24 24" fill="#141106" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M11 4C7.13401 4 4 7.13401 4 11C4 14.866 7.13401 18 11 18C12.8866 18 14.5988 17.2537 15.8576 16.0402C15.8837 16.0064 15.9123 15.9738 15.9433 15.9428C15.9743 15.9118 16.0068 15.8833 16.0406 15.8572C17.2539 14.5984 18 12.8863 18 11C18 7.13401 14.866 4 11 4ZM18.0321 16.6174C19.2636 15.0778 20 13.1249 20 11C20 6.02944 15.9706 2 11 2C6.02944 2 2 6.02944 2 11C2 15.9706 6.02944 20 11 20C13.1251 20 15.0782 19.2634 16.618 18.0317L20.2933 21.707C20.6838 22.0975 21.317 22.0975 21.7075 21.707C22.098 21.3165 22.098 20.6833 21.7075 20.2928L18.0321 16.6174Z"
      fill="#828282"
    />
  </svg>
));

export const Success = memo<IconProps>(props => (
  <svg width={108} height={108} viewBox="0 0 108 108" fill="none" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M69.8781 16.7996C61.9006 13.2451 52.9879 12.3645 44.4691 14.2892C35.9503 16.2139 28.2819 20.8408 22.6076 27.4798C16.9332 34.1188 13.557 42.4141 12.9823 51.1287C12.4077 59.8433 14.6655 68.5102 19.4189 75.8368C24.1724 83.1634 31.1668 88.7571 39.3591 91.7837C47.5514 94.8103 56.5026 95.1077 64.8777 92.6314C73.2528 90.1552 80.6031 85.038 85.8323 78.043C91.0616 71.0481 93.8896 62.5502 93.8946 53.8166V49.6792C93.8946 47.1939 95.9093 45.1792 98.3946 45.1792C100.88 45.1792 102.895 47.1939 102.895 49.6792V53.8192C102.888 64.4935 99.432 74.8824 93.0407 83.4318C86.6495 91.9812 77.6658 98.2356 67.4296 101.262C57.1933 104.289 46.253 103.925 36.2402 100.226C26.2274 96.5268 17.6786 89.69 11.8688 80.7353C6.059 71.7806 3.2995 61.1877 4.00183 50.5365C4.70417 39.8854 8.83071 29.7466 15.766 21.6323C22.7013 13.518 32.0738 7.86293 42.4857 5.51051C52.8975 3.15809 63.7909 4.23436 73.5411 8.57879C75.8112 9.5903 76.8315 12.2506 75.82 14.5207C74.8085 16.7908 72.1482 17.8112 69.8781 16.7996Z"
      fill="#06A701"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M101.575 14.6353C103.333 16.3918 103.335 19.241 101.578 20.9992L56.5781 66.0442C55.7343 66.8888 54.5895 67.3635 53.3957 67.3638C52.2018 67.3641 51.0567 66.89 50.2126 66.0458L36.7126 52.5458C34.9552 50.7885 34.9552 47.9392 36.7126 46.1819C38.4699 44.4245 41.3192 44.4245 43.0765 46.1819L53.3929 56.4983L95.211 14.6385C96.9674 12.8802 99.8167 12.8788 101.575 14.6353Z"
      fill="#06A701"
    />
  </svg>
));

export const Burger = memo<IconProps>(props => (
  <svg width={32} height={32} viewBox="0 0 32 32" fill="#828282" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M2.66797 15.9934C2.66797 15.257 3.26492 14.6601 4.0013 14.6601H28.0013C28.7377 14.6601 29.3346 15.257 29.3346 15.9934C29.3346 16.7298 28.7377 17.3268 28.0013 17.3268H4.0013C3.26492 17.3268 2.66797 16.7298 2.66797 15.9934Z"
      stroke="none"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M2.66797 7.99343C2.66797 7.25705 3.26492 6.6601 4.0013 6.6601H28.0013C28.7377 6.6601 29.3346 7.25705 29.3346 7.99343C29.3346 8.72981 28.7377 9.32676 28.0013 9.32676H4.0013C3.26492 9.32676 2.66797 8.72981 2.66797 7.99343Z"
      stroke="none"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M2.66797 23.9934C2.66797 23.257 3.26492 22.6601 4.0013 22.6601H28.0013C28.7377 22.6601 29.3346 23.257 29.3346 23.9934C29.3346 24.7298 28.7377 25.3268 28.0013 25.3268H4.0013C3.26492 25.3268 2.66797 24.7298 2.66797 23.9934Z"
      stroke="none"
    />
  </svg>
));
