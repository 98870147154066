import { setUserForTracking } from 'analytics/amplitude';
import { useState, useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';

const ONE_HOUR = 60 * 60 * 1000;
const ONE_DAY = 24 * ONE_HOUR;

export const isStaging = () => {
  return (
    process.env.REACT_APP_API_URL ===
    'http://portal-alb-1261168480.us-west-2.elb.amazonaws.com/api'
  );
};

export const isProduction = () => {
  return process.env.NODE_ENV === 'production';
};

const isDev = () => {
  return !process.env.NODE_ENV || process.env.NODE_ENV === 'development';
};

const formatImageUrl = (url: string) => {
  return url;
};

const getExpirationTime = (ms: number) => {
  const days = Math.floor(ms / ONE_DAY);
  const hours = Math.floor((ms % ONE_DAY) / ONE_HOUR);

  return { days, hours };
};

const getWindowDimensions = () => {
  const { innerWidth: width, innerHeight: height } = window;
  return {
    width,
    height,
  };
};

const useWindowDimensions = () => {
  const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions());

  useEffect(() => {
    function handleResize() {
      setWindowDimensions(getWindowDimensions());
    }

    window.addEventListener('resize', handleResize);

    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return windowDimensions;
};

export { isDev, formatImageUrl, getExpirationTime, useWindowDimensions };

const PUBLIC_ID_KEY = 'publicId';

export const useSavePublicIdInLocalStorage = () => {
  const [searchParams] = useSearchParams();
  const publicId = searchParams.get('public_id');

  useEffect(() => {
    if (publicId) {
      setUserForTracking(publicId);
      localStorage.setItem(PUBLIC_ID_KEY, publicId);
    }
  }, [publicId]);
};

export const savePublicIdInLocalStorage = (publicId: string) => {
  setUserForTracking(publicId);
  localStorage.setItem(PUBLIC_ID_KEY, publicId);
};

export const getPublicIdFromLocalStorage = () => {
  const publicId = localStorage.getItem(PUBLIC_ID_KEY);

  if (publicId) {
    setUserForTracking(publicId);
  }

  return publicId || '';
};
