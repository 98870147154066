import amplitude from 'amplitude-js';

export const initAmplitude = (id?: string) => {
  if (process.env.NODE_ENV !== 'production') {
    return;
  }

  const publicId = id || undefined;

  const key = '1ef6ace8701d0caf63f1238a1a46c128';
  amplitude.getInstance().init(key, publicId, {
    includeReferrer: true,
    disableCookies: true,
  });
};

const identifyPage = (href: string) => {
  const subdomain = window.location.host?.split('.')[0];
  if (subdomain === 'market' || href.includes('/market')) {
    return 'market';
  }

  if (href.includes('/events/christmas')) {
    return 'adventCalendar';
  }
  if (href.includes('/preset/')) {
    return 'preset';
  }
  if (href.includes('/achievements')) {
    return 'achievements';
  }
  if (href.includes('/user/')) {
    return 'user';
  }

  return 'search';
};

export const trackEvent = (name: string, data?: Record<string, any>) => {
  const href = window.location.href;

  if (process.env.NODE_ENV !== 'production') {
    return;
  }

  const page = identifyPage(href);

  amplitude.getInstance().logEvent(name, { page, ...data });
};

export const setUserForTracking = (id: string) => {
  if (process.env.NODE_ENV !== 'production') {
    return;
  }

  if (!id) {
    return;
  }

  initAmplitude(id);
  amplitude.getInstance().setUserId(id);
};
